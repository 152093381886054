<template>
  <div>
    <v-dialog v-model="dialog" width="800">
      <template v-slot:activator="{ on, attrs }" v-if="permite_editar">
        <v-btn plain v-bind="attrs" v-on="on" @click="get_analise_sugestoes" fab>
          <v-icon medium class="mr-2">
            mdi-pencil
          </v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <v-app-bar color="grey" dense dark>
            <v-toolbar-title>
              Administrador: Sugestão #{{ currentItem.id }} - {{ currentItem.usuario.text }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn @click.stop="atualizar()" plain icon>
              <v-icon>mdi-content-save-edit</v-icon>
            </v-btn>
            <v-btn plain icon @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-app-bar>
        </v-card-title>
        <v-card-text>
          <v-tabs v-model="tab">
            <v-tab key="sugestao">Sugestão</v-tab>
            <v-tab key="status">Status</v-tab>
            <v-tab key="avaliacao">Avaliação</v-tab>
            <v-tab key="observacoes">Observações</v-tab>
            <v-tab key="acoes">Ações</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item key="sugestao">
              <v-card elevation="2" class="mb-3">
                <v-card-text>
                  <v-container>
                    <v-form ref="form">
                      <v-card class="pa-2" outlined tile>
                        <v-row>
                          <v-col cols="2" class="font-weight-bold text-center">ID</v-col>
                          <v-col>
                            {{ currentItem.id }}
                          </v-col>
                        </v-row>
                      </v-card>

                      <v-card class="pa-2" outlined tile>
                        <v-row>
                          <v-col cols="2" class="font-weight-bold text-center">TITULO</v-col>
                          <v-col>
                            {{ currentItem.titulo }}
                          </v-col>
                        </v-row>
                      </v-card>

                      <v-card class="pa-2" outlined tile>
                        <v-row>
                          <v-col cols="2" class="font-weight-bold text-center">SETOR</v-col>
                          <v-col>
                            {{ currentItem.local.name }}
                          </v-col>
                        </v-row>
                      </v-card>
                      <v-card class="pa-2" outlined tile>
                        <v-row>
                          <v-col cols="2" class="font-weight-bold text-center">SUGESTÃO</v-col>
                          <v-col>
                            {{ currentItem.sugestao }}
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-form>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item key="status">
              <v-card elevation="2">
                <v-card-text>
                  <v-container>
                    <v-row justify="start">
                      <v-col>
                        <v-card elevation="2">
                          <v-card-title>
                            <v-row>
                              <v-col :md='4' :cols="12">
                                Status
                              </v-col>
                            </v-row>
                          </v-card-title>
                          <v-card-text>
                            <v-container>
                              <v-row justify="start">
                                <v-col class="text-left">
                                  <v-timeline align-top dense>
                                    <v-timeline-item v-for="m in currentItem.movimentos" :key="m.id + 'm'"
                                      :color="m.resultado.cor" right>
                                      <span :class="`${m.resultado.cor}--text`">
                                        {{ m.entrega }} {{ m.resultado.text }} <br>e a previsão de devolução é em {{
                                            m.created_at | formatData
                                        }}

                                        <v-switch v-if="false" class="text-right" @click="atualizarMovimento(m, true)"
                                          v-model='m.recebida'
                                          :label="`${m.recebida ? 'Recebida' : 'clique aqui para declarar recebimento.'}`"
                                          :loading="loading"></v-switch>
                                      </span>
                                    </v-timeline-item>
                                  </v-timeline>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item key="avaliacao">
              <v-card class="pa-2 mt-2" outlined>
                <v-card class="pa-2 mt-3" outlined tile>
                  <v-row>
                    <v-col>Avaliador faça a sua análise e dê o seu parecer final</v-col>
                    <v-col cols="12">
                      <v-textarea outlined :readonly="!isAva" v-model="currentItem.analise" :clearable="isAva"
                        clear-icon="mdi-close-circle" :loading="loading">

                      </v-textarea>
                    </v-col>

                  </v-row>
                </v-card>

                <v-card class="pa-2" outlined tile>
                  <v-row>
                    <v-col cols="12">Se aprovado como será implementado.</v-col>
                    <v-col cols="12">
                      <v-textarea outlined v-model="currentItem.como" :clearable="isAva" clear-icon="mdi-close-circle"
                        :readonly="!isAva">

                      </v-textarea>
                    </v-col>
                  </v-row>
                </v-card>

                <v-card class="pa-2" outlined tile>
                  <v-row>
                    <v-col cols="2" class="font-weight-bold text-right">Previsão de custos:</v-col>
                    <v-col cols="10">
                      <v-text-field outlined label="R$" :clearable="isAva" v-model.lazy="currentItem.quanto"
                        v-money="money" :readonly="!isAva">

                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-card>

                <v-card class="pa-2" outlined tile v-if="currentItem.avaliador">
                  <v-row>
                    <v-col cols="2" class="font-weight-bold text-right">Avaliador:</v-col>
                    <v-col>
                      {{ currentItem.avaliador.text }}
                    </v-col>
                  </v-row>
                </v-card>

                <v-card class="pa-2" outlined tile>
                  <v-row>
                    <v-col cols="2" class="font-weight-bold text-right">Criado em:</v-col>
                    <v-col>
                      {{ currentItem.created_at | formatData }}
                    </v-col>
                  </v-row>
                </v-card>

                <v-card class="pa-2" outlined tile>
                  <v-row>
                    <v-col cols="2" class="font-weight-bold text-right">Modificado em:</v-col>
                    <v-col>
                      {{ currentItem.updated_at | formatData }}
                    </v-col>
                  </v-row>
                </v-card>

                <v-card class="pa-2" outlined tile>
                  <v-row>
                    <v-col cols="2" class="font-weight-bold text-right">Criado por:</v-col>
                    <v-col>
                      {{ currentItem.created_by }}
                    </v-col>
                  </v-row>
                </v-card>

                <v-card class="pa-2" outlined tile>
                  <v-row>
                    <v-col cols="2" class="font-weight-bold text-right">Modificado por:</v-col>
                    <v-col>
                      {{ currentItem.updated_by }}
                    </v-col>
                  </v-row>
                </v-card>
              </v-card>

            </v-tab-item>
            <v-tab-item key="observacoes">
              <v-card class="pa-2" outlined tile>
                <v-row>
                  <v-col cols="2" class="font-weight-bold text-right">
                    Observações:
                  </v-col>
                  <v-col cols="9">
                    <v-textarea outlined v-model="data_analises_sugestoes.text" clearable clear-icon="mdi-close-circle">
                    </v-textarea>
                  </v-col>
                  <v-col class="text-right text-botton" cols="1">
                    <v-btn @click.stop="post_analise_sugestoes()" outlined color="green" icon>
                      <v-icon>mdi-content-save-outline</v-icon>
                    </v-btn>
                    &nbsp;
                  </v-col>
                </v-row>

                <div class="text-right mt-2" v-if="currentItem.observacoes.length > 0">
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        {{ currentItem.observacoes.length }} Itens
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row dense v-for="s in currentItem.observacoes" :key="s.id + 's'">
                          <v-col offset="2">

                            <v-card class="ma-2 pa-2" color="grey lighten-2" outlined x-large>
                              <v-row>
                                <v-col class="caption grey--text text-right">
                                  Usuario: {{ s.created_by }}, em: {{ s.created_at | formatData }}
                                </v-col>
                              </v-row>

                              <v-row>
                                <v-col>
                                  {{ s.text }}
                                </v-col>
                              </v-row>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>
              </v-card>
            </v-tab-item>
            <v-tab-item key="acoes">
              <v-card class="ma-5 pa-5">
                <v-row class="text-center">
                  <v-col :md='6' :cols="12">
                    <v-autocomplete v-model="currentItem.avaliador" @change="merge(currentItem)" :items="usuarios"
                      return-object label=" Escolha o avaliador " :loading="loading"></v-autocomplete>
                  </v-col>
                  <v-col :md="6" :cols="12" class="text-right">
                    <v-btn v-if="show_atualiza_movimento" @click="atualizarMovimento(null, 'ava')" :disabled='etapa'
                      outlined>
                      Enviar para o avaliador
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row v-if="ativa_envio_usuario">
                  <v-col :md='6' :cols="12">
                    <v-select :items="resposta" v-model="resultado" return-object label="Resultado" @change="gravar">
                    </v-select>
                  </v-col>
                  <v-col :md='6' :cols="12" class="text-right">
                    <v-btn @click="atualizarMovimento(null, 'usuario')" :disabled='etapa' outlined>
                      Devolver para o usuário
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="loading" height="300" width="500" :color="cor">
      {{ mensagem }}

      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" @click="fechaLoading" text>
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Edit',
  components: {},
  props: {
    currentItem: {},
    isAva: { type: Boolean },
    isAdm: { type: Boolean },
  },
  data: () => ({
    dialog: false,
    tab: null,
    show_atualiza_movimento: false,
    resultado: '',
    data_analises_sugestoes: {},
    conf: {},
    config_current: {},
    config_resultado: {
      method: 'get',
      url: 'informe/resultado/',
      headers: {
        'Content-Type': 'application/json'
      },
    },
    //config_analise_status:{},
    money: {
      decimal: ',',
      thousands: '.',
      prefix: 'R$ ',
      precision: 2,
      masked: false
    },
    usuario: JSON.parse(localStorage.getItem('cfgu')),
  }),
  computed: {
    ...mapGetters([
      'analise_sugestoes',
      'perm',
      'usuarios',
      'movimentos',
      'resultados',
      'cor',
      'mensagem',
      'loading',
      'adm',
      'ava',
      'lista_3_array',
      'lista_3',]),
    etapa() {
      return this.currentItem.avaliador != '' ? false : true
    },
    resposta() {
      return this.resultados.filter(e => e.macro > 0)
    },
    devolvidoParaAdministrador() {
      return this.usuario.conf.devolvido_para_adm == this.currentItem.resultado.id
    },
    ativa_envio_usuario() {
      return this.currentItem.ultimo_movimento.resultado.id == 8
    },
    permite_editar() {
      return this.currentItem.ultimo_movimento.resultado.id != 4
    }
  },
  methods: {
    ...mapActions(['btnLoading']),
    gravar() {
      this.$store.dispatch('btnLoading', true)
      this.$store.dispatch('lista', {
        method: 'put',
        url: this.currentItem.update_url,
        data: this.currentItem,
        instancia: 'lista_3_array'
      })
      this.atualizar()
    },
    atualizar() {
      this.$store.dispatch('lista', {
        url: 'informe/sugestoes/',
        instancia: 'lista_3_array'
      })
    },
    fecha() {
      this.$store.dispatch('btnLoading', false)
    },
    get_analise_sugestoes() {
      this.$store.dispatch('listaAnaliseSugestoes', {
        method: 'get',
        url: 'informe/analises-sugestoes/?sugestao=' + this.currentItem.id,
        instancia: 'analise-sugestoes',
      })
      this.show_atualiza_movimento = this.currentItem.resultado.id != null // this.conf.conf.enviado_para_avaliador
      //this.$store.dispatch('movimentos',{url: `informe/movimentos-sugestoes/?sugestao=${this.currentItem.id}`})
    },
    post_analise_sugestoes() {
      this.$store.dispatch('lista', {
        method: 'post',
        url: 'informe/analises-sugestoes/',
        data: this.data_analises_sugestoes,
        loading: true,
        instancia: 'lista_3',
      })
      this.get_analise_sugestoes
      this.data_analises_sugestoes.text = ''
    },
    fechaLoading() {
      this.$store.dispatch('btnLoading', false)
    },
    atualizarMovimento(val, met) {
      this.$store.dispatch('btnLoading', true)
      let metodo = 'post'
      let url = ''
      let data = {}
      if (met == 'adm') {
        url = 'informe/movimentos-sugestoes/'
        data.sugestao = this.currentItem.id
        data.resultado = this.conf.conf.devolvido_para_adm
        metodo = 'post'
      } else if (met == 'ava') {
        url = 'informe/movimentos-sugestoes/'
        data.sugestao = this.currentItem.id
        data.resultado = this.conf.conf.enviado_para_avaliador
        metodo = 'post'
      } else if (met == 'usuario') {
        url = 'informe/movimentos-sugestoes/'
        data.sugestao = this.currentItem.id
        data.resultado = this.resultado.id
        metodo = 'post'
      } else {
        data = val
        url = data.update_url
        metodo = 'put'

      }
      let config = {
        method: metodo,
        url: url,
        data: data,
        loading: true,
      }
      this.$store.dispatch('movimentos', config)
    },
    merge(val) {
      this.$store.dispatch('btnLoading', true)
      this.$store.dispatch('sugestoes', {
        method: 'put',
        url: val.update_url,
        data: val,
        loading: false,
        instancia: 'sugestoes'
      })
    },

  },
  mounted() {
    // this.$store.dispatch('perm')
    this.config_current = {
      method: 'put',
      url: 'informe/sugestoes/' + this.currentItem.id + '/',
      data: this.currentItem,
      loading: true
    }
    this.conf = JSON.parse(localStorage.getItem('cfgu'))
    // this.$store.dispatch('usuarios', { url: `contas/usuarios/?papel=${this.conf.conf.papel_parcial}` })
    this.$store.dispatch('resultados', { url: `informe/resultados/` })
    this.data_analises_sugestoes.sugestao = this.currentItem
  },
}
</script>